import { Component, OnInit, HostListener } from '@angular/core';
import { ApplicationService, AuthService, Guest, LogoutService } from '@frontend/common';
import { ActivityLogService } from '@frontend/core';

import { User } from '@frontend/common';
import { MainNavigationLink } from '@frontend/common';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, Subscription } from 'rxjs';

@Component({
  selector: 'ciprofile-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  logoutObs;
  user: User = null;
  guest: Guest;
  userSubscription : Subscription;
  guestSubscription : Subscription;
  isAuthenticated: boolean = false;
  loading = false;
  error = null;
  mainNavigationLinks: MainNavigationLink[];
  brandLogoBanner: string; // temporary solution TODO move this logic elsewhere
  headerScrollOffsetExceeded = false;
  currentRouteIsHomepage: boolean;

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const scrollPosition = (event.target as HTMLElement).scrollTop || 0;
    const headerHeight = document.querySelector("nav")?.offsetHeight || 0;
    // console.log(scrollPosition+': Scroll Position of mainSection');
    // console.log(headerHeight+': headerHeight');
    if (scrollPosition >= headerHeight) {
      this.headerScrollOffsetExceeded = true;
    } else {
      this.headerScrollOffsetExceeded = false;
    }
  }


  constructor(
    private authService: AuthService,
    private activityLogService: ActivityLogService,
    private logoutService : LogoutService,
    private applicationService : ApplicationService,
    private router : Router,
  ) {
    this.mainNavigationLinks = [
      {labelText: 'Snapshots', labelLanguageKey : 'survey.snapshots', routerLinkValue: ['/snapshots'], condition: 'hideIfNoUserAndNoGuest'},
    ];
  }

  logout() {
    this.loading = true;
    this.logoutObs = this.logoutService.logout();
    this.logoutObs.subscribe(
      (response) => {
        this.loading = false;
      },
      (error) => {
        this.error = error;
        this.loading = false;
      }
    );
  }
  
  getUser() {
    this.loading = true;
    this.error = null;

    let getUserSub = this.authService.getUser(null).subscribe(
      () => {
        this.loading = false;
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.loading = false;
      }
    );
  }
  handleNoSubdomain (){
    this.mainNavigationLinks = this.mainNavigationLinks.filter(l=>l.labelText !== 'Snapshots');
  }
  ngOnInit() {
    if(!this.applicationService.getSubdomain()){
      this.handleNoSubdomain();
    }
    this.userSubscription = this.authService.user.subscribe(
      (user) => {
        this.user = user;
        this.isAuthenticated = !!user;
        this.activityLogService.logDefaultSiteVisit();
      },
      (error) => {
        this.activityLogService.logDefaultSiteVisit();
      }
    );
    this.guestSubscription = this.authService.guest.subscribe(guest => {
      this.guest = guest;
    });
    if (this.authService.token) {
      this.getUser();
    }
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.currentRouteIsHomepage = this.router.url === '/';
    })
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    if (this.guestSubscription){
      this.guestSubscription.unsubscribe();
    }
    this.logoutObs.unsubscribe();
  }
}
