<div class="grey-card-placeholder {{contentJustificationClasses}}" [style]="'height: '+height+'px;'">
  <p class="h4" *ngIf="titleText">
    <i [class]="iconClasses" *ngIf="iconClasses"></i>{{titleText}}
  </p>
  <p class="mt-2" *ngIf="bodyText1">
    {{bodyText1}}
  </p>
  <p class="mt-2" *ngIf="bodyText2">
    {{bodyText2}}
  </p>
</div>