import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'frontend-user-list-item-simple-type-one',
  templateUrl: './user-list-item-simple-type-one.component.html',
  styleUrls: ['./user-list-item-simple-type-one.component.scss']
})
export class UserListItemSimpleTypeOneComponent implements OnInit {

  @Input() user;
  @Input() linkTextTranslation : string;
  @Input() linkText : string;
  @Input() truncateLinkText : boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
