<div class="authenticity-graph" [ngClass]="'w-'+width">

  <div class="graph-body" *transloco="let t" (mouseenter)="endAnimation()" (mouseleave)="startAnimation()">

    <!-- Avatar -->
    <!-- Center is 50%:50% --- remove class "move-me" when graphic is hovered -->
    <div
      class="avatar"
      [ngClass]="{'move-me': !isHovered && playAnimation}"
      (mouseenter)="applyHoverClass()"
      (mouseleave)="removeHoverClass()"
      [style]="'--move-from-top: '+avatarPositionY+'%; --move-from-left: '+avatarPositionX+'%;'"
      >
      <img draggable="false" *ngIf="avatarUrl" [src]="avatarUrl" alt="avatar">

        <span class="icon" *ngIf="!avatarUrl"><i class="fa-solid fa-user"></i></span>

      </div>
    <!-- End of Avatar -->

    <!-- Dot Grid -->
    <div class="clip-dots" (click)="onClick($event)">
      <div class="hover-boxes">
        <div class="box"></div>
        <div class="box right"></div>
        <div class="box bottom-left"></div>
        <div class="box bottom-right"></div>
      </div>
      <div class="dots"></div>
      <div class="dots right"></div>
      <div class="dots bottom-left"></div>
      <div class="dots bottom-right"></div>
    </div>
    <!-- End of Dot Grid -->
    <div class="text-box-holder">
      <p class="text-box top-left" *ngIf="message === 'top-left'">{{t('ci_profile_authenticity.danger_top_left')}}</p>
      <p class="text-box top-right" *ngIf="message === 'top-right'">{{t('ci_profile_authenticity.danger_top_right')}}</p>
      <p class="text-box bottom-left" *ngIf="message === 'bottom-left'">{{t('ci_profile_authenticity.danger_bottom_left')}}</p>
      <p class="text-box bottom-right" *ngIf="message === 'bottom-right'">{{t('ci_profile_authenticity.danger_bottom_right')}}</p>
    </div>

    <!-- Top Border -->
    <div class="border-content w-100">
      <button type="button" class="corner-circle" [ngClass]="{active: message === 'top-left'}">
        <div class="middle-circle">
          <i class="fa-solid fa-circle solid-circle"></i>
        </div>
      </button>
      <div class="px-2 d-flex flex-row align-items-center w-100">
        <div class="text-holder">
          <div class="horizontal-splitter"></div>
          <p>{{t('ci_profile_authenticity.their_style')}}</p>
          <div class="horizontal-splitter"></div>
        </div>
        <!-- <div class="axis-divider"></div> -->
        <i class="fa-solid fa-circle axis-divider-font"></i>
        <div class="text-holder">
          <div class="horizontal-splitter"></div>
          <p>{{t('ci_profile_authenticity.their_style')}}</p>
          <div class="horizontal-splitter"></div>
        </div>
      </div>
      <button type="button" class="corner-circle" [ngClass]="{active: message === 'top-right'}">
        <div class="middle-circle">
          <i class="fa-solid fa-circle solid-circle"></i>
        </div>
      </button>
    </div>
    <!-- End of Top Border -->

    <!-- Middle Content -->
    <div class="border-content">
      <!-- Left Border -->
      <div class="side-border">
        <div class="text-holder">
          <div class="vertical-splitter"></div>
          <p class="left">{{t('ci_profile_authenticity.our_priorities')}}</p>
          <div class="vertical-splitter"></div>
        </div>

        <i class="fa-solid fa-circle axis-divider-font"></i>

        <div class="text-holder">
          <div class="vertical-splitter"></div>
          <p class="left">{{t('ci_profile_authenticity.our_priorities')}}</p>
          <div class="vertical-splitter"></div>
        </div>
      </div>
      <!-- End of Left Border -->

      <div class="axis-labels">
        <p class="x-text left">{{t('ci_profile_authenticity.what')}}</p>
        <p class="x-text right">{{t('ci_profile_authenticity.what')}}</p>
        <p class="y-text top">{{t('ci_profile_authenticity.how')}}</p>
        <p class="y-text bottom">{{t('ci_profile_authenticity.how')}}</p>
      </div>

      <svg viewBox="0 0 414 414">
        <path class="xy-arrows"
          d="m413.71 206.29-6.36-6.36a.996.996 0 1 0-1.41 1.41l4.66 4.66H208.01V3.41l4.66 4.66a.996.996 0 1 0 1.41-1.41L207.72.3a.996.996 0 0 0-1.41 0l-6.36 6.36a.996.996 0 1 0 1.41 1.41l4.66-4.66V206H3.41l4.66-4.66a.996.996 0 1 0-1.41-1.41L.3 206.29a.996.996 0 0 0 0 1.41l6.36 6.36a.996.996 0 1 0 1.41-1.41l-4.66-4.66H206v202.59l-4.66-4.66a.996.996 0 1 0-1.41 1.41l6.36 6.36c.39.39 1.02.39 1.41 0l6.36-6.36a.996.996 0 1 0-1.41-1.41l-4.66 4.66V207.99h202.59l-4.66 4.66a.996.996 0 1 0 1.41 1.41l6.36-6.36a.996.996 0 0 0 0-1.41Z" />
      </svg>

      <!-- Right Border -->
      <div class="side-border">
        <div class="text-holder">
          <div class="vertical-splitter"></div>
          <p class="right">{{t('ci_profile_authenticity.their_priorities')}}</p>
          <div class="vertical-splitter"></div>
        </div>

        <i class="fa-solid fa-circle axis-divider-font"></i>

        <div class="text-holder">
          <div class="vertical-splitter"></div>
          <p class="right">{{t('ci_profile_authenticity.their_priorities')}}</p>
          <div class="vertical-splitter"></div>
        </div>
      </div>
      <!-- End of Right Border -->
    </div>
    <!-- End of Middle Content -->

    <!-- Bottom Border -->
    <div class="border-content">
      <button type="button" class="corner-circle" [ngClass]="{active: message === 'bottom-left'}">
        <div class="middle-circle">
          <i class="fa-solid fa-circle solid-circle"></i>
        </div>
      </button>
      <div class="px-2 d-flex flex-row align-items-center w-100">
        <div class="text-holder">
          <div class="horizontal-splitter"></div>
          <p>{{t('ci_profile_authenticity.our_style')}}</p>
          <div class="horizontal-splitter"></div>
        </div>
        <i class="fa-solid fa-circle axis-divider-font"></i>
        <div class="text-holder">
          <div class="horizontal-splitter"></div>
          <p>{{t('ci_profile_authenticity.our_style')}}</p>
          <div class="horizontal-splitter"></div>
        </div>
      </div>
      <button type="button" class="corner-circle" [ngClass]="{active: message === 'bottom-right'}">
        <div class="middle-circle">
          <i class="fa-solid fa-circle solid-circle"></i>
        </div>
      </button>
    </div>
    <!-- End of Bottom Border -->
  </div>
</div>