import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'multisite-nav-dropdown-doublecheck',
  templateUrl: './nav-dropdown-doublecheck.component.html',
  styleUrls: ['./nav-dropdown-doublecheck.component.scss']
})
export class NavDropdownDoublecheckComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
