<div class="statistic d-flex flex-column flex-md-row align-items-start gap-3">
  @if(statistic()){
  <p class="display rounded-statistic {{ statisticClasses() }}">
    {{ statistic() }}
  </p>
  } @else if(media()) {

  <img
    class="display {{ mediaClasses() }}"
    [src]="
      media().asset_url && imageTransformations()
        ? media().base_url + imageTransformations() + media().asset_url
        : media().file_url
    "
    [alt]="media().description"
  />
  }
  <span class="d-flex flex-column" *transloco="let t">
    @if(titleText() || titleTranslationKey()){
    <p class="mb-3 {{ titleClasses() }}">
      {{ titleTranslationKey() ? t(titleTranslationKey()) : titleText() }}
    </p>
    } @if(descriptionText() || descriptionTranslationKey()){
    <p>
      {{
        descriptionTranslationKey()
          ? t(descriptionTranslationKey())
          : descriptionText()
      }}
    </p>
    }
  </span>
</div>
