import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'multisite-placeholder-card',
  templateUrl: './placeholder-card.component.html',
  styleUrls: ['./placeholder-card.component.scss']
})
export class PlaceholderCardComponent implements OnInit {

  @Input() titleText : string = 'Placeholder card'; // "Course Four"
  @Input() bodyText1 : string = 'Details of this placeholder card'; // "Course Details for beginners"
  @Input() bodyText2 : string; // "Here is all you need to know about the course and the lessons it contains"
  @Input() iconClasses : string = 'fa-solid fa-arrow-up-right-dots mr-2'; // "fa-solid fa-arrow-up-right-dots mr-2"
  @Input() height : number = 160; // 160
  @Input() contentJustificationClasses : string = ' flex-column py-5'; // make this null for simple icon + title only

  constructor() { }

  ngOnInit(): void {
  }

}
