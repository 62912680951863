<div class="card-gray-200 mt-3 cartainity-score-card" *transloco="let t">
  <div class="py-1">
    <a class="h5 mb-0 d-flex w-100 align-items-center justify-content-between" (click)="collapsed = !collapsed"
      [attr.aria-expanded]="!collapsed" aria-controls="chartView">
      <span class="card-header d-flex flex-column">
        <span class="card-name">{{ titleTranslationKey ? t(titleTranslationKey) : titleText }}</span> <span
          class="card-value">{{round(certainty)}}%</span>
      </span>
      <button type="button" class="btn link-secondary">
        <i class="fa-solid fa-angle-down mt-2"></i>
      </button>
    </a>
  </div>
  <div id="chartView" [collapse]="collapsed" [isAnimated]="true">
    <ngx-charts-gauge class="chart" *ngIf="!refreshingChart && single" [view]="view" [scheme]="colorScheme"
      [results]="single" [legend]="" [min]="0" [max]="100" [angleSpan]="180" [startAngle]="-90" [showAxis]="true"
      [showText]="true">
    </ngx-charts-gauge>
    <div class="card-body">
      <!-- <h5 class="card-title">{{ titleTranslationKey ? t(titleTranslationKey) : titleText }}</h5> -->
      <p class="card-text text-muted small" *ngIf="helpTranslationKey || helpText">
        <i *ngIf="helpIconClasses" [class]="'mr-1 '+helpIconClasses"></i>
        {{helpTranslationKey ? t(helpTranslationKey) : helpText}}
        <a class="link-primary-dark text-decoration-underline ml-1" (click)="lessInfo = !lessInfo"
          [attr.aria-expanded]="!lessInfo" aria-controls="moreInfo">
          {{lessInfo ? t('common.more_info') : t('common.show_less')}}
        </a>
      </p>
      <div id="moreInfo" [collapse]="lessInfo" [isAnimated]="true">

        <span>
          <p class="card-text text-muted small item-separator" *ngFor="let paragraph of editedParagraphTranslations()">
            {{t(paragraph)}}</p>
        </span>

        <a class="card-link" [routerLink]="['/open-science']"
          routerLinkActive="router-link-active">{{t('platform.about_research')}}</a>
      </div>
    </div>
  </div>

</div>