<div class="d-flex flex-column justify-content-center align-items-center" *transloco="let t">
  <div class="icon-holder">
    <i class="{{iconClasses ?? 'fa-solid fa-unlock-alt'}}"></i>
  </div>
  <p class="title h5 font-weight-bold mt-3">{{ titleTranslationKey ? t(titleTranslationKey) : titleText }}</p>
  <p class="mb-2" *ngIf="descriptionTranslationKey || descriptionText">{{ descriptionTranslationKey ? t(descriptionTranslationKey) : descriptionText }}</p>
  <button type="button" [class]="ctaButtonClasses" [ngClass]="{'w-auto': smallButton}" (click)="doLinkClick()">
    {{cta_translationKey ? t(cta_translationKey) : cta_text }}
    <i class="fa-solid fa-arrow-right ml-2"></i>
  </button>
</div>