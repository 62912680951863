import { Component, Input, Output, EventEmitter } from '@angular/core';
import { User, UserRole } from '../../auth';

@Component({
  selector: 'multisite-user-account-dropdown',
  templateUrl: './user-account-dropdown.component.html',
  styleUrls: ['./user-account-dropdown.component.scss']
})
export class UserAccountDropdownComponent {

  @Input() user : User;
  @Output() logout = new EventEmitter();


  constructor() { }

  doLogout(){
    this.logout.emit();
  };

  filterRoles(roles : UserRole[],arrayOfRoleTitles : string[]){
    let filteredRoles = [];
    if(roles && arrayOfRoleTitles?.length){
      arrayOfRoleTitles.forEach(t => {
        let role = roles.find(r=>r.title === t);
        if (role){
          filteredRoles.push(role);
        }
      });
    }
    return filteredRoles.length;
  }

}
