import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'frontend-alert-type-one',
  templateUrl: './alert-type-one.component.html',
  styleUrls: ['./alert-type-one.component.scss']
})
export class AlertTypeOneComponent implements OnInit {

  @Input() classes : string; // 'alert-warning'
  @Input() titleClass : string;
  @Input() titleText : string;
  @Input() titleTextTranslation : string; // "'COMMON.SOMETHING_IMPORTANT'"
  @Input() bodyText : string;
  @Input() bodyTextTranslation : string; // "'COMMON.SOMETHING_USEFUL'"
  @Input() linkText : string;
  @Input() linkTextTranslation : string; // "'COMMON.MORE_INFO'"
  @Input() navigateArray : any[]; // ['/home']
  @Input() emitLinkAction : boolean;
  @Output() linkEmitter = new EventEmitter();

  constructor( private router : Router) { }

  doLinkEvent (){
    if (this.emitLinkAction){
      this.linkEmitter.emit();
    } else {
      this.router.navigate(this.navigateArray);
      
    };
  }

  ngOnInit(): void {
  }

}
