import { Component, OnInit, Input } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

import { Video } from '@frontend/core';

@Component({
  selector: 'frontend-video-player-type-one',
  templateUrl: './video-player-type-one.component.html',
  styleUrls: ['./video-player-type-one.component.scss']
})
export class VideoPlayerTypeOneComponent implements OnInit {

  // THIS COMPONENT IS NOT COMPATIBLE WITH NEW VIMEO REQUIREMENTS - check VideoPlayerTypeTwoComponent for differences (host_params added and some other small changes)
 
  @Input() video : Video;
  @Input() classes : string;// 'mb-3'

  iframeSrc : SafeResourceUrl;

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    if (this.video?.host_identifier){

      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/'+this.video.host_identifier);

      /* var options = {
        url: "https://vimeo.com/558020191",
        // width: 800
      };
      var videoPlayer = new Vimeo.Player('myVideo', options);

      videoPlayer.on('play', function() {
        console.log('Played the video');
      }); */
      
      const s = this.renderer2.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://player.vimeo.com/api/player.js'
      this.renderer2.appendChild(this._document.body, s);
    }
  }

}
