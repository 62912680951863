import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'rating-single-factor-with-results',
  templateUrl: './rating-single-factor-with-results.component.html',
  styleUrls: ['./rating-single-factor-with-results.component.scss']
})
export class RatingSingleFactorWithResultsComponent implements OnChanges {
  
  @Input() text: string;
  @Input() translationKey: string; // 
  @Input() max: number;
  @Input() rate: number;
  @Input() isReadonly: boolean;
  @Input() loading: boolean;
  @Output() submitRating = new EventEmitter<number>();
  
  overStar: number | undefined;


  constructor() { }

  confirmSelection (){
    this.submitRating.emit(this.rate);
  }

  hoveringOver(value: number): void {
    this.overStar = value;
  }

  resetStar(): void {
    this.overStar = this.rate;
  }

  ngOnChanges(changesObject): void {
    if (changesObject.rate && !changesObject.rate.isFirstChange()){
      this.rate = this.rate ? this.rate : 0;
    }
  }

}
