import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MediaService } from '@frontend/common';
import { IconLinkListLinkGroup } from './icon-link-list-link-group.model';
import { IconLinkListLink } from './icon-link-list-link.model';


@Component({
  selector: 'frontend-link-icon-list',
  templateUrl: './link-icon-list.component.html',
  styleUrls: ['./link-icon-list.component.scss']
})
export class LinkIconListComponent implements OnInit {

  @Input() headingText : string; // "Learning resources"
  @Input() headingTranslationKey : string; // "survey.resources"
  @Input() buttonLinkImageUrl : string; // "https://cultureconnector.com/images/6036d824681823e6489fbe4b6786e68a.png"
  @Input() buttonUrl : string; // "https://cultureconnector.com/"
  @Input() buttonLinkImageAlt : string; // "CultureConnector"
  @Input() buttonLinkIconClasses : string; // 'fa-solid fa-arrow-up-right-from-square';
  @Input() linkGroups: IconLinkListLinkGroup[];
  @Input() linkHelpTranslationKey: string; // 'common.open_link'
  @Output() linkClicked = new EventEmitter<IconLinkListLink>()
  @Output() headingLinkClicked = new EventEmitter()
  cloudinary_base_url : string; // 'https://res.cloudinary.com/cebt/image/upload/'

  constructor(private mediaService : MediaService) {
    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;
  }

  doLinkClicked (link : IconLinkListLink){
    this.linkClicked.emit(link);
  }
  doHeadingLinkClicked (){
    this.headingLinkClicked.emit(this.buttonUrl);
  }

  ngOnInit(): void {

  }

}
