import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'multisite-locked-content-mask',
  templateUrl: './locked-content-mask.component.html',
  styleUrls: ['./locked-content-mask.component.scss']
})
export class LockedContentMaskComponent {

  @Input() titleText : string;
  @Input() titleTranslationKey : string; // 'common.instructions'
  @Input() descriptionText : string;
  @Input() descriptionTranslationKey : string; // 'common.instructions'
  @Input() iconClasses : string; // 'fa fa-heart'
  @Input() cta_text : string; // 'Go to Tip' 
  @Input() cta_translationKey : string; // 'content.go'
  @Input() ctaButtonClasses : string = 'btn btn-sm btn-success';
  smallButton : boolean = true; // TODO make this a dynamic input in future when needed

  @Output() linkClicked = new EventEmitter();


  constructor() { }


  doLinkClick (){
    this.linkClicked.emit();
  }

}
