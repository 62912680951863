<li mdbDropdown class="nav-item dropdown logout">
  <a class="nav-link dropdown-toggle" id="navbarSettingsDropdown" role="button" mdbDropdownToggle
    aria-expanded="false">
    <i class="fa-solid fa-right-from-bracket"></i><span class="nav-link-mobile pl-2">Log Out</span>
  </a>
  <ul mdbDropdownMenu class="dropdown-menu dropdown-navbar-loggedin" aria-labelledby="navbarSettingsDropdown">
    <li class="dropdown-header">
      <p>Log Out</p>
    </li>
    <li class="logout-dropdown-body">
      <div class="dropdown-item">
        <div>
          <p>Are you sure you want to log out?</p>
          <div class="btn-grpup">
            <button class="btn btn-primary">No</button>
            <button routerLink="/login" class="btn btn-outline-primary">Yes</button>
          </div>
        </div>
      </div>
    </li>
  </ul>
</li>