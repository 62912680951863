import { Component, OnInit, Input } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'frontend-podcast-player-single',
  templateUrl: './podcast-player-single.component.html',
  styleUrls: ['./podcast-player-single.component.scss']
})
export class PodcastPlayerSingleComponent implements OnInit {
 

  @Input() meta : {js_src : string, buzzsprout_player_id : string};

  constructor(private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document) { }

  ngOnInit(): void {
    if (this.meta && this.meta.js_src){
      const s = this.renderer2.createElement('script');
      s.type = 'text/javascript';
      s.src = this.meta.js_src; // 'https://www.buzzsprout.com/1712440/8618788-how-do-you-keep-up-to-date-when-cultures-change.js?container_id=buzzsprout-player-8618788&player=small'
      s.text = ``;
      s.charset = 'utf-8';
      this.renderer2.appendChild(this._document.body, s);
    }
  }

}
