import { Component, Input, OnChanges } from '@angular/core';
import { CarouselItem } from '@frontend/common';

interface slideItem {
  url: string,
  alt: string,
  title: string,
  description: string,
}

@Component({
  selector: 'multisite-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnChanges {

  @Input() controls : boolean = false;
  @Input() interval : number = 2000; // milliseconds
  @Input() showIndicators : boolean = true;
  @Input() showCaptions : boolean = true;
  @Input() noPause : boolean; // true = continue slide progress when mouseover
  @Input() carouselItems : CarouselItem[];
  @Input() imageUrlTransformations : string;  // 'ar_16:9'
  @Input() imageInlineStyles : string;  // 'filter:brightness(50%)'
  slides : slideItem[] = [];

  constructor() { }

  ngOnChanges(changesObject): void {

    if (changesObject.carouselItems?.currentValue?.length){
      this.slides = this.carouselItems.map(ci => {
        let url : string = ci.bgImageUrl;
        if (this.imageUrlTransformations && ci.media) {
          url = ci.media.base_url + this.imageUrlTransformations + '/'+ ci.media.asset_url;
        }
        return {
          url : url,
          alt : ci.media?.description,
          title : ci.title,
          description : ci.description
        }
      })
    }


  }

}
