import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'frontend-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
