 import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  VerifyEmailLandingPageComponent,
  AuthenticationOverviewComponent,
  HasSubdomainGuard,
  HasNoSubdomainGuard,
} from '@frontend/common';
import { AuthOrGuestGuard } from '@frontend/common';
import { AuthGuard } from '@frontend/common';

import { HomeComponent } from './home/home.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path: 'login', component: AuthenticationOverviewComponent},
  { path: 'register', component: AuthenticationOverviewComponent},
  { path: 'forgot-password', component: AuthenticationOverviewComponent},
  { path: 'email/verify/:user_id/:hash', component: VerifyEmailLandingPageComponent},
  { path: 'password/reset/:hash/:email', component: AuthenticationOverviewComponent},
  
  { path: 'snapshots',canActivateChild:[HasSubdomainGuard], canActivate:[AuthOrGuestGuard], loadChildren: () => import('./app-snapshots.module').then(m => m.CiProfileSnapshotsModule), data: { label: 'Snapshots', translationKey: 'survey.snapshots', labelSource: null},},

  { path: 'community/search', canActivateChild:[HasNoSubdomainGuard], loadChildren: () => import('@frontend/common').then(m => m.CommunitySearchModule), data: { label: 'Community', translationKey: 'navigation.community', labelSource: null} },

  { path: 'community', redirectTo: 'community/search' },

  { path: 'hubs', canActivate:[AuthGuard], loadChildren: () => import('@frontend/common').then(m => m.HubsModule), data: { label: 'Hubs', translationKey: 'navigation.hubs', labelSource: null} },

  { path: 'account', canActivate:[AuthGuard], loadChildren: () => import('@frontend/common').then(m => m.UserAccountModule), data: { label: 'Account', translationKey: 'navigation.account', labelSource: null} },
  
  { path: 'connections', loadChildren: () => import('@frontend/common').then(m => m.UserConnectionsModule), data: { label: 'Connections', translationKey: 'navigation.connections', labelSource: null} },
  
  { path: 'design', canActivate:[AuthGuard], loadChildren: () => import('@frontend/common/design').then(m => m.DesignModule), data: { label: 'Design', translationKey: 'navigation.design', labelSource: null} },

  { path: 'demo', redirectTo: 'coming-soon' },
  {
    path: 'coming-soon',
    loadChildren: () =>
    import('@frontend/shared').then((m) => m.ComingSoonModule),
  },
  {
    path: 'open-science',
    loadChildren: () =>
    import('@frontend/shared').then((m) => m.OpenScienceModule),
  },
  { path: 'help/faqs', loadChildren: () => import('@frontend/common').then(m => m.FaqsModule), data: { label: 'FAQs', translationKey: 'content.faqs', labelSource: null} },


  { path: 'openscience', redirectTo: 'open-science' },
  
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
