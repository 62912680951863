import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ContentModule } from '@frontend/common';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { BannersModule, ProcessVisualisationPromoModule } from '@frontend/common';
import { AccessModule } from '@frontend/shared';
import { SurveysSnapshotStepperModule } from '@frontend/common';



@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    // ContentModule, // for the homepage carousel
    RouterModule,
    TranslocoModule,
    BannersModule,
    AccessModule,
    SurveysSnapshotStepperModule,
    ProcessVisualisationPromoModule
  ]
})
export class HomeCiProfileModule { }
