<div class="locked-mask" *transloco="let t">
  <div class="d-flex gap-3">
    <div class="icon-holder">
      <i class="fa-solid fa-lock"></i>
    </div>
    <div class="text d-flex flex-column align-items-start">
      <p class="title h5 font-weight-bold">{{ titleTranslationKey ? t(titleTranslationKey) : titleText }}</p>
      <p class="mb-2">{{ descriptionTranslationKey ? t(descriptionTranslationKey) : descriptionText }}</p>
      <button type="button" [class]="ctaButtonClasses" [ngClass]="{'w-auto': smallButton}" (click)="doLinkClick()">
        {{cta_translationKey ? t(cta_translationKey) : cta_text }}
        <i class="fa-solid fa-arrow-right ml-2"></i>
      </button>
    </div>
  </div>
</div>