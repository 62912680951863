import { Component, Input, OnInit } from '@angular/core';
import { CarouselItem } from '../../models/carousel-item.model';

@Component({
  selector: 'multisite-carousel-page-body-fill',
  templateUrl: './carousel-page-body-fill.component.html',
  styleUrls: ['./carousel-page-body-fill.component.scss']
})
export class CarouselPageBodyFillComponent implements OnInit {

  @Input() controls : boolean;
  @Input() indicators : boolean;
  @Input() animation : string; // 'slide' or 'fade'
  @Input() dark : boolean; // for black captions and controls
  @Input() carouselItems : CarouselItem[];

  constructor() { }

  ngOnInit(): void {
  }

}
