import { Component, input, signal } from '@angular/core';
import { CloudinaryMediaAsset } from '../../../models';
import { MediaService } from '../../media';

@Component({
  selector: 'multisite-media-statistic-card',
  templateUrl: './media-statistic-card.component.html',
  styleUrl: './media-statistic-card.component.scss',
})
export class MediaStatisticCardComponent {

  statistic = input<string>('');
  statisticClasses = input<string>('h1');
  media = input<CloudinaryMediaAsset | undefined >(undefined);
  mediaClasses = input<string>('');
  imageTransformations = input<string >('w_150,c_fill,ar_1:1/');
  titleText = input<string>('');
  titleTranslationKey = input<string>('');
  titleClasses = input<string>('h5');
  descriptionText = input<string>('');
  descriptionTranslationKey = input<string>('');
  cloudinary_base_url = signal<string|undefined>(undefined);

  constructor(private mediaService: MediaService) {
    this.cloudinary_base_url.set(this.mediaService.cloudinary_base_url);
  }



}
