import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService, AuthService, BrandDisplayService, CloudinaryMediaAsset, ContentItem, GalleryService, Guest, LanguageService, Snapshot, SnapshotService, User, WindowService } from '@frontend/common';
import { HubMicro } from '@frontend/common';
import { Survey } from '@frontend/common';
import { SurveysService } from '@frontend/common';
import { Media, StatisticNumerical } from '@frontend/core';
import { Subscription } from 'rxjs';



@Component({
  selector: 'ciprofile-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  surveys : Survey[];
  surveysAsContent : ContentItem[];
  snapshots: Snapshot[];
  user: User;
  guest: Guest;
  loading: boolean;
  loadingArray: {name:string, loading: boolean}[] = [];
  hubMicro: HubMicro;
  featureImage : CloudinaryMediaAsset;
  bannerImage : CloudinaryMediaAsset;
  hubMicroSubscription : Subscription;
  defaultBannerImage : CloudinaryMediaAsset;
  gallerySubscription: Subscription;
  galleryCtaMedia: Media[];
  activeLanguageSubscription: Subscription;
  userSubscription: Subscription;
  guestSubscription: Subscription;
  snapshotsSubscription: Subscription;
  subdomain: string;
  communityStatistic : StatisticNumerical;
  eventCodeError: string; // 'common.expired_or_incorrect'
  eventCodeErrorStepper: string; // 'common.expired_or_incorrect'
  siteSpecificStepOneTranslationKeys: {
    step:string, // 'ciprofile_content.stepper.take_time'
    title:string, // 'ciprofile_content.stepper.take_time_title'
    text:string, // 'ciprofile_content.stepper.take_time_text'
  };


  constructor(
    private router : Router,
    private route : ActivatedRoute,
    private surveysService: SurveysService,
    private brandDisplayService : BrandDisplayService,
    private applicationService: ApplicationService,
    private windowService: WindowService,
    private languageService : LanguageService,
    private snapshotService : SnapshotService,
    private authService : AuthService,
    private galleryService : GalleryService,
    ) { 
      this.defaultBannerImage = 
      {
        id: 0,
        medially_type:  'App\\Models\\Page',
        medially_id: 8,
        file_url: 'https://res.cloudinary.com/cebt/image/upload/v1697563836/scenes/banners/passengers-motion-SBI-300616823-storyblocks.jpg',
        file_name: 'scenes/banners/passengers-motion-SBI-300616823-storyblocks',
        file_type: "image",
        size: 4010000,
        uploader_id: 1,
        description: "People walking thorough an airport",
        credits: "Storyblocks",  // "Richard Farkas",
        permission: "Storyblocks subcription", // null,
        category: "concepts", // "intercultural",
        type: "travel", // "concepts",
        created_at: null, // "2022-10-31T13:00:03.000000Z",
        updated_at: null, // "2022-10-31T13:00:03.000000Z"
        // we add the following here in the frontend:
        base_url: 'https://res.cloudinary.com/cebt/image/upload/',
        transformations_url: null, //w_700,c_fill,ar_16:9/
        asset_url: 'scenes/banners/passengers-motion-SBI-300616823-storyblocks.jpg'
      }
      this.siteSpecificStepOneTranslationKeys = {
        step: 'ciprofile_content.stepper.take_time',
        title: 'ciprofile_content.stepper.take_time_title',
        text:  'ciprofile_content.stepper.take_time_text',
      }; 
    }
    isLoading(name:string){
      if(name){
        return this.loadingArray.find(l=>l.name === name && l.loading);
      }
      return this.loadingArray.find(l => l.loading);
    }
    toggleLoading (name:string, loading: boolean){
      let index = this.loadingArray.findIndex(l=>l.name === name);
      if (index > -1){
        this.loadingArray[index].loading = loading;
      } else {
        this.loadingArray.push({name,loading});
      }
    }
    getSurveys(category : string,type : string, freshFromServer: boolean){
      this.loading = true;
      this.surveysService.getSurveys (category,type,null,freshFromServer).subscribe( response =>{
        this.surveys = response;
        this.surveysAsContent = this.surveysService.convertSurveysToContentItems(this.surveys);
        this.loading = false;
    },
      error => {
        this.loading = false;
      })
    }
  
  gotoSurvey(surveysAsContentItem: ContentItem){
    if (surveysAsContentItem){
      this.router.navigate(['quiz/'+surveysAsContentItem.slug], {relativeTo: this.route});
    }
  }
  handleHubBranding (hubMicro: HubMicro){
      this.hubMicro = hubMicro;
      if (this.hubMicro?.media?.length){
        this.featureImage = this.hubMicro.media.find(m => m.category==='profile'&& m.type==='feature');
        this.bannerImage = this.hubMicro.media.find(m => m.category==='profile'&& m.type==='general');
      } else {
        this.bannerImage = this.defaultBannerImage;
      }
  }

  navigateTo (slug){
    this.router.navigate(['/'+slug]);
  }
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
  submitEventCode(code:string,source :string = null){
    this.toggleLoading('code',true);
    if (code){
      if(source ==='stepper'){// this solves a problem where showing the error in the parent home component (out of viewport) moves the position of the stepper down; We can remove this workaround if we implement toasters here instead.
        this.eventCodeErrorStepper = 'common.expired_or_incorrect';
      } else {
        this.eventCodeError = 'common.expired_or_incorrect';
      }
      setTimeout(()=>{
        this.eventCodeError = null;
        this.eventCodeErrorStepper = null;
        this.toggleLoading('code',false);
      },5000)
    } else {
      this.eventCodeError = null;
      this.toggleLoading('code',false);
    }
  }
  getSnapshots(freshFromServer: boolean){
    this.loading = true;
    this.snapshotsSubscription = this.snapshotService.getSnapshots(freshFromServer).subscribe( response =>{
      this.snapshots = response;
      this.loading = false;
  },
    error => {
      this.loading = false;
    })
  }
  ngOnInit(): void {
    this.windowService.goToTop();
    this.subdomain = this.applicationService.getSubdomain();
    if(!this.subdomain){
      this.communityStatistic = {number:100, titleTranslationKey: 'common.interculturalists', titleText:null};
    }
    this.getSurveys('cultures','knowledge',false);
    this.hubMicroSubscription = this.brandDisplayService.currentHubMicro.subscribe(hubMicro =>{
      this.handleHubBranding(hubMicro);
    });
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( () => {
      this.getSurveys('cultures','knowledge',true);
    })
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
      this.getSnapshots(false);
    });
    this.guestSubscription = this.authService.guest.subscribe(guest => {
      this.guest = guest;
      this.getSnapshots(false);
    });
    this.gallerySubscription = this.galleryService.getGallery('ciprofile','snapshot_intro').subscribe(result=>{
      if(result?.data.length > 4){
        this.galleryCtaMedia = result.data.slice(0,3);
      } else {
        this.galleryCtaMedia = result.data;
      }
    })
  }

  ngOnDestroy () {
    if (this.activeLanguageSubscription){
      this.activeLanguageSubscription.unsubscribe();
    }
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    }
    if (this.guestSubscription){
      this.guestSubscription.unsubscribe();
    }
    if (this.snapshotsSubscription){
      this.snapshotsSubscription.unsubscribe();
    }
    if(this.gallerySubscription){
      this.gallerySubscription.unsubscribe();
    }
  }

}
