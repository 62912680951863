<span dropdown (onShown)="onShown()" (onHidden)="onHidden()" (isOpenChange)="isOpenChange()"[autoClose]="true">

      <a *ngIf="activeLanguageObject" dropdownToggle class="nav-link dropdown-toggle" id="languageSelectorTrigger" role="button" [attr.aria-label]="activeLanguageObject.flag_class" (click)="false" aria-controls="languages-dropdown">
        <i [class]="activeLanguageObject.flag_class + ' flag m-0'"></i>
        <!-- {{t(activeLanguageObject.name)}} -->
        <!-- <span class="language-name">ENG</span> -->
      </a>


      <span *transloco="let t">

        <ul id="languages-dropdown" *dropdownMenu class="dropdown-menu dropdown-languages"
            role="menu" aria-labelledby="languageSelectorTrigger">
            <li class="search-languages">
              <form class="input-group">
                <input type="search" type="search" class="form-control rounded" placeholder="Search"
                  aria-label="Search" />
              </form>
            </li>
          <li *ngFor="let language of availableLanguages">
            <a class="dropdown-item" (click)="selectLanguage(language.languageKey)"><i [class]="language.flag_class+' flag'" [attr.aria-label]="t('language.'+language.languageKey)"></i>{{t('language.'+language.languageKey)}}</a>
          </li>
        </ul>
      </span>
</span>