import { Component, OnInit } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'frontend-podcast-player-multiple',
  templateUrl: './podcast-player-multiple.component.html',
  styleUrls: ['./podcast-player-multiple.component.scss']
})
export class PodcastPlayerMultipleComponent implements OnInit {

  constructor(private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document) { }

  ngOnInit(): void {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://www.buzzsprout.com/1712440.js?player=large&limit=10&container_id=buzzsprout-large-player-1712440-limit-10';
    s.text = ``;
    this.renderer2.appendChild(this._document.body, s);
  };

}
