<div class="cultural-difference" *transloco="let t">

  <!-- <h5>Debugging</h5>
    <p>
      <b>left_extra_wide.width</b>: {{calculateGridPosition(rangeCentre)?.left_extra_wide.width}}<br>
      <b>left_wide.width</b>: {{calculateGridPosition(rangeCentre)?.left_wide.width}}<br>
      <b>core.width</b>: {{calculateGridPosition(rangeCentre)?.core.width}}<br>
      <b>right_wide.width</b>: {{calculateGridPosition(rangeCentre)?.right_wide.width}}<br>
      <b>right_extra_wide.width</b>: {{calculateGridPosition(rangeCentre)?.right_extra_wide.width}}<br>
    </p> -->
  <div class="title-holder">
    <p class="title h4" *ngIf="!pole1TranslationKey && !pole1Text">{{titleTranslationKey ? t(titleTranslationKey) :
      titleText}}</p>
    <!-- <div class="relation d-flex" *ngIf="pole1TranslationKey || pole1Text">
        <div class="pole1">{{pole1TranslationKey ? t(pole1TranslationKey) : pole1Text}}</div>
        <div class="vs">:</div>
        <div class="pole2">{{pole2TranslationKey ? t(pole2TranslationKey) : pole2Text}}</div>
      </div> -->
  </div>

  <div class="scale">
    <div class="pole-holder h6" *ngIf="pole1TranslationKey || pole1Text">
      <p class="text-start w-100">{{pole1TranslationKey ? t(pole1TranslationKey) : pole1Text}}</p>
      <div class="icon-holder" *ngIf="iconClasses">
        <i class="{{iconClasses}}"></i>
      </div>
      <p class="text-end w-100 h6">{{pole2TranslationKey ? t(pole2TranslationKey) : pole2Text}}</p>
    </div>
    <div class="grid-holder" *ngIf="showRangeGrid && (rangeCentre || rangeCentre === 0)">
      <div class="benchmark" [ngClass]="calculateGridPosition(rangeCentre)?.left_extra_wide.class"
        [style]="'--width: '+calculateGridPosition(rangeCentre)?.left_extra_wide.width+'%;'"
        *ngIf="calculateGridPosition(rangeCentre)?.left_extra_wide.show">
        {{t('ci_profile_analysis.extra_wide_abbreviation')}}</div>
      <div class="benchmark" [ngClass]="calculateGridPosition(rangeCentre)?.left_wide.class"
        [style]="'--width: '+calculateGridPosition(rangeCentre)?.left_wide.width+'%;'"
        *ngIf="calculateGridPosition(rangeCentre)?.left_wide.show">{{t('ci_profile_analysis.wide_abbreviation')}}</div>
      <div class="benchmark" [ngClass]="calculateGridPosition(rangeCentre)?.core.class"
        [style]="'--width: '+calculateGridPosition(rangeCentre)?.core.width+'%;'"
        *ngIf="calculateGridPosition(rangeCentre)?.core.show">{{t('ci_profile_analysis.narrow_abbreviation')}}</div>
      <div class="benchmark" [ngClass]="calculateGridPosition(rangeCentre)?.right_wide.class"
        [style]="'--width: '+calculateGridPosition(rangeCentre)?.right_wide.width+'%;'"
        *ngIf="calculateGridPosition(rangeCentre)?.right_wide.show">{{t('ci_profile_analysis.wide_abbreviation')}}</div>
      <div class="benchmark" [ngClass]="calculateGridPosition(rangeCentre)?.right_extra_wide.class"
        [style]="'--width: '+calculateGridPosition(rangeCentre)?.right_extra_wide.width+'%;'"
        *ngIf="calculateGridPosition(rangeCentre)?.right_extra_wide.show">
        {{t('ci_profile_analysis.extra_wide_abbreviation')}}</div>
    </div>
    <div class="slider-holder" style="--range: {{range}}%; --range-centre: {{rangeCentre}}%">
      <div class="position-icon" style="--icon-centre: {{iconPosition}}%"
        *ngIf="iconPosition === 0 || iconPosition > 0">
        <i [class]="iconClasses"></i>
      </div>
      <div class="difference-slider">
        <div class="range-indicator">
          <div class="range"></div>
          <div class="range start"></div>
          <div class="range middle"></div>
        </div>
      </div>
    </div>
    <!-- pole -->
  </div>
</div>