<div class="row">

  <!-- Grid column -->
  <div class="col-3 col-sm-2">

    <!--Image-->
      <a>
        <img class="img-fluid rounded-circle z-depth-1 w-100" [src]="user.picture" [alt]="user.fname+' '+user.lname">
      </a>

  </div>
  <!-- Grid column -->

  <!-- Grid column -->
  <div class="col-9 col-sm-10">

    <!-- Excerpt -->
    <h5 class="dark-grey-text">
      <a class="title-link">{{user.fname+" "+user.lname}}</a>
    </h5>
    <div class="d-flex justify-content-between">
      <div class="col-11 pl-0" [ngClass]="{'text-truncate': truncateLinkText}">
        <a class="dark-grey-text">
          <span *ngIf="linkTextTranslation; else elseBlock">
            <span *transloco="let t">
                <span> {{ t(linkTextTranslation) }} </span>
            </span>
          </span>
          <ng-template #elseBlock>
            <span> {{ linkText }} </span>
          </ng-template>

        </a>
      </div>
      <div class="d-flex flex-column">
        <a class="my-auto">
          <i class="fas fa-angle-double-right"></i>
        </a>
      </div>
    </div>

  </div>
  <!-- Grid column -->

</div>