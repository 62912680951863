import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'multisite-authenticity-visualisation',
  templateUrl: './authenticity-visualisation.component.html',
  styleUrls: ['./authenticity-visualisation.component.scss']
})
export class AuthenticityVisualisationComponent implements OnInit {

  @Input() width : number = 100; // percent of the parent container
  @Input() avatarUrl : string; // 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd9w9n2QpLNyuJlWAQfjblEHWAhRk8izJLUA&usqp=CAU'
  isHovered : boolean = false;

  avatarPositionY : number = 75; // percent of div.graph-body from top
  avatarPositionX : number = 75; // percent of div.graph-body from left
  message : string;
  playAnimation : boolean = true;
  animationInterval : any;
  clicked :boolean; // one-time variable; we'll play the animation until clicked

  constructor() { }

  selectMessage (avatarPositionY: number, avatarPositionX: number ) : string {
    if ((avatarPositionY <= 50 && avatarPositionX <= 50) && (avatarPositionY < 40 || avatarPositionX < 40)) {
      return 'top-left';
    }
    if ((avatarPositionY <= 50 && avatarPositionX > 50) && (avatarPositionY < 40 || avatarPositionX > 60)) {
      return 'top-right';
    }
    if ((avatarPositionY > 50 && avatarPositionX > 50) && (avatarPositionY > 60 || avatarPositionX > 60)) {
      return 'bottom-right';
    }
    if ((avatarPositionY > 50 && avatarPositionX <= 50) && (avatarPositionY > 60 || avatarPositionX < 40)) {
      return 'bottom-left';
    }

    return ''; 
  }

  onClick(event : MouseEvent){
    
    let clickX = event.offsetX
    let clickY = event.offsetY
    
    const target = event.currentTarget as HTMLElement;
    let clientHeight = target.offsetHeight;
    let clientWidth = target.offsetWidth;
    
    // @ts-ignore
    const parentElement = event.currentTarget.offsetParent; //TODO -update types; TS gives incorrect error here

    if (parentElement) {
      const offsetX = event.clientX - parentElement.getBoundingClientRect().left;
      const offsetY = event.clientY - parentElement.getBoundingClientRect().top;

      /* with click handler on the clip-dots level */
      this.avatarPositionY = (offsetY/clientHeight)*100;
      this.avatarPositionX = (offsetX/clientWidth)*100;

    }

    this.clicked = true;
    this.endAnimation();

    /* with click handler on the graph-level
    this.avatarPositionY = (clickY/clientHeight)*100;
    this.avatarPositionX = (clickX/clientWidth)*100;
    */
    this.message = this.selectMessage(this.avatarPositionY,this.avatarPositionX);
  }

  applyHoverClass() {
    this.isHovered = true;
  }

  endAnimation (){
    clearInterval(this.animationInterval);
    if (this.playAnimation){ // first time we're ending the animation
      this.avatarPositionX = 50;
      this.avatarPositionY = 50;
    }
    this.playAnimation = false;
  }

  removeHoverClass() {
    this.isHovered = false;
  }

  startAnimation (){
    
    if (this.clicked){return;};

    this.playAnimation = true;

    this.animationInterval = setInterval(()=>{
      if (this.avatarPositionX == 50 && this.avatarPositionY == 50){
        this.avatarPositionX = 75;
        this.avatarPositionY = 75;
      } else if (this.avatarPositionX == 75 && this.avatarPositionY == 75){
        this.avatarPositionX = 25;
        this.avatarPositionY = 75;
      } else if (this.avatarPositionX == 25 && this.avatarPositionY == 75){
        this.avatarPositionX = 25;
        this.avatarPositionY = 25;
      } else if (this.avatarPositionX == 25 && this.avatarPositionY == 25){
        this.avatarPositionX = 75;
        this.avatarPositionY = 25;
      } else if (this.avatarPositionX == 75 && this.avatarPositionY == 25){
        this.avatarPositionX = 75;
        this.avatarPositionY = 75;
      } else {
        this.endAnimation();
      } 
    },3000)
  }

  ngOnInit(): void {
    this.startAnimation();
  }

  ngOnDestroy (){
    clearInterval(this.animationInterval);
  }

}
