<div class="icon-links-card card-gray-200" *transloco="let t">
    <div class="header flex-wrap gap-2" *ngIf="buttonLinkIconClasses || buttonLinkImageAlt || buttonLinkImageUrl || headingText ||headingTranslationKey">
      <p class="h5 font-weight-bold mb-0" *ngIf="headingText || headingTranslationKey">{{headingText ?? t(headingTranslationKey)}}</p>
      <a type="button" class="btn header-btn" href="{{buttonUrl}}" target="_blank" (click)="doHeadingLinkClicked()" *ngIf="buttonLinkIconClasses || buttonLinkImageAlt || buttonLinkImageUrl">
        <img [src]="buttonLinkImageUrl" alt="buttonLinkImageAlt">
        <i class="fa-solid fa-arrow-up-right-from-square"></i>
      </a>
    </div>
    <div class="body" *ngFor="let linkGroup of linkGroups">
      <div class="d-flex align-items-center gap-2 px-3">
        <span *ngIf="linkGroup.titleIcon">
          <i [class]="linkGroup.titleIcon.identifier_1+' fa-xl'" *ngIf="linkGroup.titleIcon.identifier_1"></i>
          <img *ngIf="!linkGroup.titleIcon.identifier_1 && linkGroup.titleIcon.identifier_2" [src]="cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/'+linkGroup.titleIcon.identifier_2+'.svg'" class="mr-1">
          </span>
        <img *ngIf="linkGroup.titleIconUrl"
          [src]="linkGroup.titleIconUrl"
          [alt]="linkGroup.titleText ?? t(linkGroup.titleTranslationKey)">
        <h3 class="font-weight-bold mb-0">
            {{linkGroup.titleText ?? t(linkGroup.titleTranslationKey)}}
        </h3>
      </div>
      <!-- Link item -->
      <a (click)="doLinkClicked(link)" href="{{link.url}}" target="_blank" class="link-item" *ngFor="let link of linkGroup.links">
        <div class="left-side">
          <div [ngClass]="{'icon-group': link.imageIconUrls?.length + link.icons?.length > 1}">
            <img *ngFor="let icon of link.imageIconUrls"
            [src]="icon"
            [alt]="link.labelText ?? link.labelTranslationKey">
          <span *ngFor="let icon of link.icons">
            <i [class]="icon.identifier_1" *ngIf="icon.identifier_1"></i>
            <img *ngIf="!icon.identifier_1 && icon.identifier_2" [src]="cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/'+icon.identifier_2+'.svg'" class="mr-1">
          </span>
          </div>
          <p>{{t(link.labelText ?? link.labelTranslationKey)}}</p>
        </div>
        <div class="right-side">
          <a (click)="doLinkClicked(link)">
            <i class="fa-solid fa-link mr-2"></i><span *ngIf="link.linkHelpTranslationKey">{{t(link.linkHelpTranslationKey)}}</span>
          </a>
        </div>
      </a>
      
    </div>
  </div>