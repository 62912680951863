<!-- <mdb-carousel
  [controls]="false"
  [indicators]="true"
  [animation]="'fade'"
>

  <mdb-carousel-item *ngFor="let item of carouselItems" [interval]="item.interval">

    <div
      class="bg-image"
      [style]="
        'background-image: ' +
        item.bgImageStyles +
        ',url(\'' +
        item.bgImageUrl +
        '\');' +
        item.bgImageContainerStyles
      "
    ></div>

    <div class="carousel-caption d-none d-md-block" *transloco="let t">

      <h5 class="h3">{{t(item.title)}}</h5>

      <p>{{t(item.description)}}</p>

    </div>

  </mdb-carousel-item>

</mdb-carousel> -->
