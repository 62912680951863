import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { AvailableLanguage } from '../available-language.model';


@Component({
  selector: 'language-selector-type-one',
  templateUrl: './language-selector-type-one.component.html',
  styleUrls: ['./language-selector-type-one.component.scss']
})
export class LanguageSelectorTypeOneComponent implements OnInit {

  @ViewChild('dropdown') dropdown;
  @Output() onLanguageSelect = new EventEmitter<string>()
  @Input() availableLanguages : AvailableLanguage[] = [];
  @Input() activeLanguageObject : AvailableLanguage;


  constructor() {
    this.availableLanguages = []
   }


  selectLanguage(languageKey:string){
    this.onLanguageSelect.emit(languageKey);
  }

  isOpenChange(){
    // do something
  }
  onShown(){
    // do something
  }
  onHidden(){
    // do something 
  }

  ngOnInit(): void {
  }

}
