import { Component, Input, OnInit } from '@angular/core';
import { CloudinaryMediaAsset, MediaService } from '@frontend/common';
@Component({
  selector: 'multisite-user-score-display',
  templateUrl: './user-score-display.component.html',
  styleUrls: ['./user-score-display.component.scss']
})
export class UserScoreDisplayComponent implements OnInit {
  
  @Input() titleText: string; // 'Jerry Seinfeld'
  @Input() titleTranslationKey: string; // 
  @Input() subtitleText: string; // 'Actor and comedian'
  @Input() subtitleTranslationKey: string; // 
  @Input() imageUrl: string; // 'https://res.cloudinary.com/cebt/image/upload/v1635728232/users/avatars/fiona-price-linkedin_pgmtu9.jpg'
  @Input() fallbackImage: string;
  @Input() imageTransformations : string; // 'w_275,c_thumb,ar_16:9/'
  @Input() media: CloudinaryMediaAsset;
  @Input() score: number;


  constructor (private mediaService : MediaService){

  }

  ngOnInit(): void {

    if (!this.imageUrl && !this.media && !this.fallbackImage){
      this.imageUrl = this.mediaService.fallback_avatar_url;
    };
    this.imageUrl = this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(this.imageUrl, this.imageTransformations);
  }

}
