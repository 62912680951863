<div class="row">
  <div class="col-lg-12">
    <div class="rating-card-static d-flex flex-column align-items-center">
      <img class="img-responsive w-100" *ngIf="media"
      [src]="media.asset_url && imageTransformations ? media.base_url + imageTransformations + media.asset_url : media.file_url"
      [alt]="media?.description ? media.description : titleText">
      <img class="img-responsive w-100" *ngIf="!media"
      [src]="imageUrl ? imageUrl : fallbackImage"
      [alt]="media?.description ? media.description : titleText">
      <p class="h5 name font-weight-bold">
        {{titleText}}
      </p>
      <p class="position text-muted">
        {{subtitleText}}
      </p>
      <p class="rating-value h1 font-weight-bold mb-0">
        {{score}}
      </p>
    </div>
  </div>
  
</div>