import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'multisite-branding-home',
  templateUrl: './branding-home.component.html',
  styleUrls: ['./branding-home.component.scss']
})
export class BrandingHomeComponent implements OnInit {

  showSuccessAlert : boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
