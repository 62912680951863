<carousel
  [interval]="interval"
  [showIndicators]="true"
  [isAnimated]="true"
  >
  <slide *ngFor="let item of slides" class="carousel-item">
    <img
      [src]="item.url"
      [alt]="item.alt"      
      [style]="
        'background-image: ,url(\'' +
        item.bgImageUrl +
        '\');' +
        item.bgImageStyles+ imageInlineStyles
      "
      >
    <div class="carousel-caption d-none d-md-block" *ngIf="showCaptions">
      <span *transloco="let t">
        <h3 class="h3">{{t(item.title)}}</h3>
        <p>{{t(item.description)}}</p>
      </span>
    </div>
  </slide>

</carousel>