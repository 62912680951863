<div *transloco="let t">
  <multisite-jumbo-greeting [bannerImageTransformations]="'h_500,c_fill,ar_16:9/'"
    [featureImageTransformations]="'h_500,c_fill/'" [featureImage]="featureImage" [bannerImage]="bannerImage"
    [titleTranslationKey]="'ciprofile_content.promotion.bring_intelligence_with_you'"
    [subtitleTranslationKey]="'ciprofile_content.promotion.when_you_cross_cultures'"></multisite-jumbo-greeting>

  <div class="container d-flex flex-column">
    <multisite-access-code-user-input (eventCodeSubmitted)="submitEventCode($event)" [eventCodeError]="eventCodeError"
      [loading]="isLoading('code')"></multisite-access-code-user-input>

    <div class="mt-5 mb-6 py-5" *ngIf="subdomain">
      <multisite-surveys-snapshot-stepper
        [siteSpecificStepOneTranslationKeys]="siteSpecificStepOneTranslationKeys"
        [user]="user"
        [guest]="guest"
        [snapshots]="snapshots"
        [snapshotIntroMedia]="galleryCtaMedia"
        (eventCodeSubmitted)="submitEventCode($event,'stepper')"
        [eventCodeError]="eventCodeErrorStepper" [loading]="isLoading('code')">
      </multisite-surveys-snapshot-stepper>
    </div>

    <div class="my-6 py-5" *ngIf="!subdomain">
      <multisite-number-thumbnails-banner
        [titleTranslationKey]="'ciprofile.get_access_title'"
        [descriptionTranslationKey]="'ciprofile.get_access_description'"
        [statistic]="communityStatistic"
        (linkClicked)="navigateTo('community')"
      ></multisite-number-thumbnails-banner>
    </div>
  </div>

</div>