<div class="rating-card">
  <div class="card">
    <div class="card-body">
      <div class="rating-heading">
        <p class="title">Customers rating</p>
        <small>THIS IS A DRAFT DESIGN not signed off, functionality not implemented yet</small>
        <div class="main-rating">
          <rating [(ngModel)]="rate" [max]="5" [readonly]="isReadonly"></rating>
          <p><span>4.7</span> out of 5</p>
        </div>
        <p class="total-ratings">Total ratings: 40</p>
      </div>
      <hr>
      <div class="summary">
        <div class="progress-container">
            <span>5 star</span>
            <progressbar [value]="84"></progressbar>
            <span>84%</span>
        </div>
        <div class="progress-container">
            <span>4 star</span>
            <progressbar [value]="9"></progressbar>
            <span>9%</span>
        </div>
        <div class="progress-container">
            <span>3 star</span>
            <progressbar [value]="4"></progressbar>
            <span>4%</span>
        </div>
        <div class="progress-container">
            <span>2 star</span>
            <progressbar [value]="2"></progressbar>
            <span>2%</span>
        </div>
        <div class="progress-container">
            <span>1 star</span>
            <progressbar [value]="1"></progressbar>
            <span>1%</span>
        </div>
      </div>
      <hr>
      <div class="rating">
        <span class="your-rating" *transloco="let t">{{ translationKey ? t(translationKey) : text }}</span>
        <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly || loading" (keyup)="confirmSelection()"
          (click)="confirmSelection()" (onHover)="hoveringOver($event)" (mouseleave)="resetStar()">
        </rating>
        <button class="btn btn-sm btn-primary mt-2">Submit</button>
        <!-- <pre class="rate-value"><b>overstar {{overStar}}</b></pre>
      <pre class="rate-value"><b>rate {{rate}}</b></pre> -->
      </div>
    </div>
  </div>
</div>