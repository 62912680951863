<!--Card-->
<div class="card testimonial-card" [style]="cardStyles">
  <!--Background color-->
  <div *ngIf="cardBannerClasses" [class]="cardBannerClasses">

    <!--Avatar-->
    <div class="avatar mx-auto">
      <img
        [src]="imgUrl ? imgUrl : media.file_url"
        class="rounded-circle img-responsive"
      />
    </div>

  </div>


  <div class="card-body">
    <!--Name-->
    <h4 class="card-title title-text">
      <span *ngIf="!nameClickable">{{ name }}</span>
      <a *ngIf="nameClickable" class="title-link" (click)="doNameClick()">{{
        name
      }}</a>
    </h4>
    <p class="text-muted">{{ subtitle }}</p>
    <hr />
    <!--Quotation-->
    <p class="card-text pt-2">
      {{ bodyText }}
    </p>
  </div>
</div>
<!--/.Card-->
