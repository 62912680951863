import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Subscription } from 'rxjs';
import { ColourService } from '../../branding';
import { LanguageService } from '../../language';
import { DataProcessingService } from '../../utilities';

@Component({
  selector: 'multisite-certainty-score-card',
  templateUrl: './certainty-score-card.component.html',
  styleUrls: ['./certainty-score-card.component.scss']
})
export class CertaintyScoreCardComponent implements OnInit {

  @Input() certainty : number;
  @Input() titleText : string;
  @Input() titleTranslationKey : string;
  @Input() helpTranslationKey: string // 'common.hq'
  @Input() helpText: string; // 'Headquarters'
  @Input() helpIconClasses: string; // 'fa-regular fa-info-circle'
  @Input() collapsed: boolean = true;
  lessInfo: boolean = true;
  paragraphTranslationKeys: {key:string,hideOnCompletion?:boolean,showOnCompletion?:boolean,viewingRaterAdjusted?:boolean}[];
  @Input() viewingRaterAdjusted : boolean;
  @Input() profileCompleted : boolean;

  single: any[];
  view: any[] = [300, 250];
  legend: boolean = false;
  legendPosition: string = 'below';
  colorScheme : {domain:string[]};
  activeLanguageSubscription : Subscription;
  activeLanguageTimeout : any;
  refreshingChart : boolean;

  
  constructor(
    private translocoService: TranslocoService,
    private languageService : LanguageService,
    private dataProcessingService : DataProcessingService,
    private colourService : ColourService
    ) {
      this.paragraphTranslationKeys = [
        {key: 'ci_profile_analysis.certainty_origins_1'},
        {key: 'ci_profile_analysis.certainty_origins_2', showOnCompletion: true},
        {key: 'ci_profile_analysis.certainty_origins_3', showOnCompletion: true},
        {key: 'ci_profile_analysis.certainty_thanks'},
        {key: 'ci_profile_analysis.certainty_you_can_increase'},
        {key: 'survey.complete_your_profile', hideOnCompletion: true},
        {key: 'ci_profile_analysis.certainty_raters_switch', showOnCompletion: true, viewingRaterAdjusted:true},
        {key: 'ci_profile_analysis.certainty_raters', viewingRaterAdjusted:true},
        {key: 'ci_profile_analysis.certainty_instructions'},
        {key: 'ci_profile_analysis.certainty_platform', showOnCompletion: true}
      ];
  }

  getTranslatedText(key: string): string {
    return this.translocoService.translate(key)
  }

  editedParagraphTranslations(){
    
    console.log('TODO: editedParagraphTranslations executes too often');

    return this.paragraphTranslationKeys
      .filter(p=>{
        return (this.profileCompleted && !p.hideOnCompletion) || (!this.profileCompleted && !p.showOnCompletion)
      })
      .filter(p=>{
        return !this.viewingRaterAdjusted  || (this.viewingRaterAdjusted && !p.viewingRaterAdjusted)
      })
      .map(p=>p.key);
    
  }

  round(num:number){
    return Math.round(num);
  }

  makeChart (){

    this.colorScheme = {
      domain: [
        this.colourService.trafficLightsAsHex[this.getScaleStep(this.certainty)]
      ]
    };
    this.single = [
      {
        "name": this.getTranslatedText('test.certainty'),
        "value": this.round(this.certainty)
      },
    ];

  }

  getScaleStep (value){
    return this.dataProcessingService.calculateStepsFromPercentScore(value,0,100,10);
  }

  ngOnInit(): void {
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        // this.single[0].name = this.getTranslatedText('test.certainty');
        // this.single = [...this.single]; // this did not work
        /* this also did not work
        let temp = this.single;
        this.single = null;
        this.single = temp;
        */
        this.refreshingChart = true;
        this.activeLanguageTimeout = setTimeout(()=>{
          this.makeChart();
          this.refreshingChart = false;
        },500) // 100ms is too short to re-initialise the chart data
      });
      this.makeChart();
  }
  ngOnDestroy(): void {
    if (this.activeLanguageTimeout){
      this.activeLanguageTimeout = null;
    }
    if (this.activeLanguageSubscription){
      this.activeLanguageSubscription.unsubscribe();
    }
  }

}
